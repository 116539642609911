<template>
  <div ref="baseModal" class="iframe-landing-modal">
    <div class="modal-wrapper">
      <button class="close-btn" @click="$emit('hideModal')">
        <close-icon fill-color="#ffffff" width="20" height="20"></close-icon>
      </button>
      <div class="iframe-modal-body" style="background-color: transparent">
        <iframe
          style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
          :src="vimeoLink"
          frameborder="0"
          :allow="allow"
          allowfullscreen
          :title="title"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onBeforeUnmount, onMounted, reactive, ref } from "vue";
import CloseIcon from "@/components/console/icons/CloseIcon.vue";

export default {
  name: "IframeModal",
  components: { CloseIcon },
  props: {
    vimeoLink: {
      type: String,
      required: true,
    },
    allow: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  emits: ["hideModal"],
  setup(props, { emit }) {
    const baseModal = ref(null);
    const contentModal = ref(null);

    const state = reactive({});

    const hideModal = ($event) => {
      if (
        baseModal.value.contains($event.target) &&
        !contentModal.value.contains($event.target)
      ) {
        emit("hideModal");
      }
    };

    onMounted(() => {
      baseModal.value.addEventListener("click", hideModal);

      // 백그라운드 스크롤 방지
      document.getElementById("app").style.overflow = "hidden";
    });

    onBeforeUnmount(() => {
      baseModal.value.removeEventListener("click", hideModal);

      document.getElementById("app").style.overflow = "auto";
    });

    return { baseModal, contentModal, state };
  },
};
</script>

<style scoped>
.iframe-landing-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1030;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.iframe-landing-modal .modal-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px;
  width: 100%;
}

.close-btn {
  position: absolute;
  border-radius: 50%;
  top: -52px;
  right: 0;
  background-color: #1c1c1f;
  width: 32px;
  height: 32px;
  padding: 0;
}

.iframe-modal-body {
  padding: 56.25% 0 0 0;
  width: 100%;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
}

@media (min-width: 992px) {
  .iframe-landing-modal .modal-wrapper {
    margin: 0 70px;
  }
}

@media (min-width: 1400px) {
  .iframe-modal-body {
    padding: 0;
    width: 1200px;
    height: 675px;
  }

  .iframe-landing-modal .modal-wrapper {
    width: 1200px;
    margin: 0 auto;
  }
}
</style>
