<template>
  <div class="ccc">
    <div class="sec1">
      <div class="con">
        <div class="sec1_head">
          <span class="main">BIGC Moment </span>
          <span class="sub">for artists</span>
        </div>
        <div class="sec1_tt">
          팬과 함께 잊을 수 없는<br class="m_show" />
          특별한 순간을 만드세요
        </div>
        <div class="sec1_desc">
          빅크 모먼트는 온오프라인 글로벌 팬덤 <br class="m_show" />
          이벤트를 위한 <br class="m_hide" />아티스트 쇼룸 및 라이브 솔루션 등
          <br class="m_show" />다양한 솔루션을 제공합니다.
        </div>

        <a
          href="https://bigc-im.notion.site/BIGC-Moment-Global-Fandom-Business-0e1759c4b84449bfa7ca4574f51d17bd"
          target="_blank"
          class="sec1_btn"
          >파트너십 자세히 보기</a
        >

        <img class="sec1_img m_hide" src="/assets/web_image/momant/111.webp" />
      </div>
    </div>

    <div class="sec2">
      <div class="con">
        <div class="sec2_tt">
          Real-time interaction <br class="m_show" />for global fans
        </div>
        <div class="sec2_desc">
          빅크 라이브 솔루션은 팬덤의 니즈에 최적화
          <br class="m_show" />되어있습니다.<br class="m_hide" />
          인공지능 MC와 실시간 번역 자막 <br class="m_show" />기능 등 새로운 팬
          이벤트 경험을 제공합니다.
        </div>

        <div class="sec2_link_con">
          <img
            src="/assets/web_image/momant/pp.svg"
            alt=""
            style="width: 16px; height: 16px"
          />
          <button class="sec2_link" @click="actions.showLiveVideo()">
            쇼릴 영상 자세히 보기
          </button>
        </div>

        <img
          style="width: 100%"
          class="m_hide"
          src="/assets/web_image/momant/333.webp"
          alt=""
        />
        <img
          style="width: 100%"
          class="m_show"
          src="/assets/web_image/momant/777.webp"
          alt=""
        />
      </div>
    </div>

    <div class="sec3">
      <div class="sec3_tt">Artist & Brand Showroom</div>
      <div class="sec3_desc">
        아티스트의 브랜드 홈을 아름다운 쇼룸으로 <br class="m_show" />
        고감도 프리미엄 경험을 만듭니다.
      </div>

      <img class="sec3_img" src="/assets/web_image/momant/555.webp" alt="" />
    </div>

    <div class="sec4">
      <div class="con">
        <div class="sec4_tt">
          글로벌 팬들과 특별한 <br class="m_show" />
          모먼트를 만들어보세요
        </div>
        <a
          href="https://bigc-im.notion.site/BIGC-Moment-Global-Fandom-Business-0e1759c4b84449bfa7ca4574f51d17bd"
          target="_blank"
          class="sec4_btn"
          >파트너십 자세히 보기</a
        >
      </div>
    </div>

    <teleport to="#teleport" :disabled="true">
      <iframe-modal
        v-if="state.showLiveVideo"
        title="라이브ᅳ소개영상"
        vimeo-link="https://player.vimeo.com/video/738501531?h=de1b1ceadc&amp;badge=0&amp;autopause=0&amp;autoplay=1&amp;loop=1&amp;player_id=0&amp;app_id=58479"
        allow="autoplay; fullscreen; picture-in-picture"
        @hideModal="
          () => {
            state.showLiveVideo = false;
          }
        "
      ></iframe-modal>
    </teleport>
  </div>
</template>

<script>
import IframeModal from "../../components/web/modals/IframeModal";
import { computed, onBeforeUnmount, onMounted, reactive } from "vue";
import helper from "@/helper";

export default {
  name: "MomentLanding",
  components: { IframeModal },
  setup() {
    const state = reactive({
      showLiveVideo: false,
      isMobile: helper.isMobile(),
      liveVideoLink: computed(() => {
        let link =
          "https://player.vimeo.com/video/738501531?h=de1b1ceadc&amp;badge=0&amp;autopause=0&amp;autoplay=1&amp;loop=1&amp;player_id=0&amp;app_id=58479";
        return state.isMobile ? link + "&amp;muted=1" : link;
      }),
    });

    const initCss = () => {
      const html = document.getElementsByTagName("html")[0].style;
      const app = document.getElementById("app").style;
      const body = document.body.style;
      html.position = "static";
      html.overflowY = "visible";
      body.position = "static";
      body.overflow = "visible";
      body.height = "auto";
      app.overflowX = "visible";
      app.overflowY = "visible";
    };

    const resetCss = () => {
      const html = document.getElementsByTagName("html")[0].style;
      const app = document.getElementById("app").style;
      const body = document.body.style;
      html.position = "static";
      html.overflowY = "hidden";
      body.position = "static";
      body.overflow = "hidden";
      body.height = "100%";
      app.overflowY = "scroll";
    };

    onMounted(() => {
      initCss();
    });

    onBeforeUnmount(() => {
      resetCss();
    });

    const actions = {
      showLiveVideo: () => {
        state.showLiveVideo = true;
      },
    };

    return {
      state,
      actions,
    };
  },
};
</script>

<style scoped>
.ccc {
  background: #fff;
  display: flex;
  flex-direction: column;
  margin-top: 56px;
}

@media (max-width: 768px) {
  .ccc {
    margin-top: 0px;
    overflow: hidden;
  }
}

.sec1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 80px;
  background-image: url("/assets/web_image/clob/bgggg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.sec1_head {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 120%;
  /* or 72px */

  text-align: center;
  margin-bottom: 20px;
  font-size: 54px;
  line-height: 120%;
}

.sec1 .con .sec1_head > .main {
  /*color: #0d0d10;*/
  background: linear-gradient(
    90.04deg,
    #eb7484 27.89%,
    #df4eef 41.43%,
    #672ff2 66.11%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.sec1 .con .sec1_head > .sub {
  color: #0d0d10;
}

.sec1_tt {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 120%;
  text-align: center;
  color: #0d0d10;
  margin-bottom: 20px;
}

.sec1_desc {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  text-align: center;

  /* TX/LT/L_Text_Second */

  color: #818287;

  margin-bottom: 40px;
}

.sec1_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 320px;
  height: 64px;
  left: 800px;
  top: 296px;
  text-decoration: none;

  /* (임시)랜딩_그라데이션_퍼플-레드 */

  background: #0d0d10;
  border-radius: 500px;

  /* S1+ */

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 125%;
  /* identical to box height, or 20px */

  /* TX/LT/L_Text_Rreverse */
  color: #ffffff;

  margin-bottom: 80px;
}

.sec1_img {
  width: 1060px;
  height: 557px;
}

.sec2 {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 200px;
  padding-bottom: 200px;
  background-image: url("/assets/web_image/momant/222.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.sec2_tt {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 120%;
  /* identical to box height, or 53px */

  text-align: center;

  /* TX/LT/L_Text_Rreverse */

  color: #ffffff;

  margin-bottom: 20px;
}

.sec2_desc {
  /* B1 */

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  /* or 20px */

  text-align: center;

  /* TX/LT/L_Text_Third */

  color: #d0d5de;

  margin-bottom: 40px;
}
.sec2_link_con {
  display: flex;
  column-gap: 6px;
  align-items: center;
  margin-bottom: 75px;
}
.sec2_link {
  cursor: pointer;
  /* S1+ */

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  /* identical to box height, or 20px */

  /* TX/LT/L_Text_Rreverse */

  color: #ffffff;

  padding-top: 0;
  padding-left: 0;
  padding-bottom: 0;
}

.sec3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  padding-top: 200px;
  padding-bottom: 304.11px;
  background-image: url("/assets/web_image/momant/444.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.sec3_tt {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 120%;
  /* identical to box height, or 53px */

  text-align: center;

  /* TX/LT/L_Text_Default */

  color: #0d0d10;

  margin-bottom: 20px;
}

.sec3_desc {
  /* B1 */

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  overflow: hidden;
  text-align: center;
  color: #818287;
  margin-bottom: 185px;
}

.sec3_img {
  min-width: 1170px;
  width: 100%;
  max-width: 1780px;
}

.sec4 {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 100px;
  padding-bottom: 100px;
  background: #f6f8fa;
}

.sec4_tt {
  /* H1 */

  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 120%;
  /* or 38px */

  text-align: center;

  /* TX/LT/L_Text_Default */

  color: #0d0d10;

  margin-bottom: 40px;
}

.sec4_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 320px;
  height: 64px;
  left: 800px;
  top: 296px;
  text-decoration: none;

  /* (임시)랜딩_그라데이션_퍼플-레드 */

  background: #0d0d10;
  border-radius: 500px;

  /* S1+ */

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 125%;
  /* identical to box height, or 20px */

  /* TX/LT/L_Text_Rreverse */
  color: #ffffff;
}
.con {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1090px;
}
.m_show {
  display: none;
}

.m_hide {
  display: initial;
}

@media (max-width: 1090px) {
  .m_show {
    display: initial;
  }
  .m_hide {
    display: none;
  }
  .con {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 360px;
  }
  .sec1_head {
    font-size: 26px;
    line-height: 125%;
    margin-bottom: 12px;
  }

  .sec1_tt {
    font-size: 26px;
    line-height: 125%;
  }
  .sec1_desc {
    font-size: 12px;
  }
  .sec1_btn {
    margin-bottom: 60px;
  }
  .sec1_img {
    width: 760px;
    height: 433px;
  }
  .sec2 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .sec2_tt {
    /* H2 */

    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 125%;
    /* or 30px */

    text-align: center;

    /* TX/LT/L_Text_Rreverse */

    color: #ffffff;
  }

  .sec2_link_con {
    margin-bottom: 40px;
  }
  .sec3 {
    padding-top: 80px;
    padding-bottom: 121px;
    background-image: url("/assets/web_image/momant/888.png");
    background-size: 400px 400px;
    background-position: center 216px;
  }
  .sec3_tt {
    font-size: 24px;
  }
  .sec3_desc {
    margin-bottom: 82px;
  }

  .sec4 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .sec4_tt {
    font-size: 24px;
  }
}
</style>
